import React from "react";
import { Link, useNavigate } from "react-router-dom";

function SolutionsDropdown({ setShowDropdownNavbarMenu }) {
  const navigate = useNavigate();
  const onClickHandler = (path) => {
    navigate(path);
    setShowDropdownNavbarMenu(false);
  };

  return (
    <div>
      <div
        onClick={() => onClickHandler("/services/9")}
        className="block text-left border-t py-3 border-red-200 text-base font-normal leading-[19.09px] hover:bg-green-100 px-2 cursor-pointer"
      >
        SmartCard Solutions
      </div>

      <div
        onClick={() => onClickHandler("/services/14")}
        className="block text-left border-t py-3 border-red-200 text-base font-normal leading-[19.09px] hover:bg-green-100 px-2 cursor-pointer"
      >
        Education Solutions
      </div>

      <div
        onClick={() => onClickHandler("/services/13")}
        className="block text-left border-t py-3 border-red-200  text-base font-normal leading-[19.09px] hover:bg-green-100 px-2 cursor-pointer"
      >
        Home Solutions
      </div>

      <div
        onClick={() => onClickHandler("/services/13")}
        className="block text-left border-t py-3 border-red-200 text-base font-normal leading-[19.09px] hover:bg-green-100 px-2 cursor-pointer"
      >
        Industrial Solutions
      </div>

      <div
        onClick={() => onClickHandler("/services/15")}
        className="block text-left border-t py-3 border-red-200 text-base font-normal leading-[19.09px] hover:bg-green-100 px-2 cursor-pointer"
      >
        Healthcare Solutions
      </div>

      <div
        onClick={() => onClickHandler("/services/12")}
        className="block text-left border-t py-3 border-red-200  text-base font-normal leading-[19.09px] hover:bg-green-100 px-2 cursor-pointer"
      >
        RFID/Barcode Solutions
      </div>

      <div
        onClick={() => onClickHandler("/services/3")}
        className="block text-left border-t py-3 border-red-200 text-base font-normal leading-[19.09px] hover:bg-green-100 px-2 cursor-pointer"
      >
        E-Commerce Solutions
      </div>

      <div
        onClick={() => onClickHandler("/services/16")}
        className="block text-left border-t py-3 border-red-200 text-base font-normal leading-[19.09px] hover:bg-green-100 px-2 cursor-pointer"
      >
        Commodity Smart Solutions
      </div>

      <div
        onClick={() => onClickHandler("/services/8")}
        className="block text-left border-t py-3 border-red-200  text-base font-normal leading-[19.09px] hover:bg-green-100 px-2 cursor-pointer"
      >
        Web & Cloud Solutions
      </div>

      <div
        onClick={() => onClickHandler("/services/2")}
        className="block text-left border-t py-3 border-red-200  text-base font-normal leading-[19.09px] hover:bg-green-100 px-2 cursor-pointer"
      >
        IOS/Android App Solutions
      </div>
    </div>
  );
}

export default SolutionsDropdown;
